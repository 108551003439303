import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ClubView from '../components/Club/ClubView';
import PolynedHeaderView from '../components/PolynedHeaderView';
import SafeWidthView from '../components/SafeWidthView';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { getEdgeDevices } from '../network/lib/EdgeDeviceManager';
import { ClubModel } from '../models/network/ClubModel';


export const ChooseClubScreen = ({ navigation }) => {
  const [clubs, setClubs] = useState<ClubModel[]>([]);

  useEffect(() => {
    getEdgeDevices().then(response => {
      const tempClubs = response.data;
      if(tempClubs.length === 1) navigation.navigate('home', tempClubs[0]);
      setClubs(tempClubs)
    });
  }, [])

  return (
    <SafeWidthView>
      <View style={styles.container}>
        <PolynedHeaderView />
        <Text style={styles.overviewText}>Overzicht</Text>
        {clubs.map(club => {
          return (
            <ClubView club={club} key={club.id} navigation={navigation} />
          );
        })}
        <View style={styles.separator} />
      </View>
    </SafeWidthView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  overviewText: {
    fontSize: 30,
    fontFamily: Fonts.monterrat,
    color: Colors.darkGray,
    alignSelf: 'flex-start'
  }
});