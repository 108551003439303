import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import Chart from 'react-apexcharts'
import { StyleSheet, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Colors from '../constants/Colors';

export const ChartScreen = () => {
  const [options] = useState<ApexOptions>({
    chart: {
      type: 'area',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    stroke: {
      curve: 'smooth'
    },
    colors: [Colors.mainColor],
    fill: {
      opacity: 1,
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    title: {
      text: 'Verbruik'
    }
  });
  const series = [{
    name: 'Bestellingen',
    data: [31, 40, 28, 51, 42, 109, 10, 23, 123, 40, 50, 89]
  }]
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.chartContainer}>
        <Chart options={options} series={series} />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  chartContainer: {
    width: '90%',
    backgroundColor: Colors.semiWhite,
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: -2,
    margin: 10
  }
});
