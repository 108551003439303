import SunFullIcon from '../../assets/vectors/SunFullIcon.svg'
import SunHalfIcon from '../../assets/vectors/SunHalfIcon.svg'
import SunOffIcon from '../../assets/vectors/SunOffIcon.svg'

interface SunIconProps {
    value: number
}

const SunIcon = (props: SunIconProps) => {
    if (props.value > 60) {
        return <SunFullIcon width={29} height={29}/>
    } else if (props.value > 20) {
        return <SunHalfIcon width={29} height={29}/>
    } else {
        return <SunOffIcon width={15} height={15}/>
    }
}

export default SunIcon;