import { View, Text, StyleSheet, ViewProps } from 'react-native'
import { LightModel } from '../../models/network/LightModel'
import StatusText from './StatusText'
import ProgressBar from '@ramonak/react-progress-bar'
import Colors from '../../constants/Colors'
import SunFullIcon from '../../assets/vectors/SunFullIcon.svg'
import AlarmIcon from '../../assets/vectors/AlarmIcon.svg'

interface LightViewProps {
    light: LightModel
}

const LightView = (props: LightViewProps) => {
    const notReachable = !props.light.state.isReachable;
    return (
        <View style={styles.container}>
            {notReachable && <Alert style={{right: 15, top: 15}}/>}
            <StatusText style={{ fontSize: 16 }}>{props.light.name}</StatusText>
            <StatusText style={{ fontSize: 10, marginTop: -1 }}>{props.light.zigbeeId}</StatusText>
            <View style={{ flex: 1, flexDirection: 'row', marginVertical: 10 }}>
                <View style={{ width: 23, height: 23, justifyContent: 'center', alignContent: 'center', marginRight: 10 }}>
                    <SunFullIcon/>
                </View>
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                        <ProgressBar height='10px' bgColor={Colors.mainColor} isLabelVisible={false} maxCompleted={255} completed={props.light.state.isOn ? props.light.state.brightness : 0} />
                    </View>
            </View>
            <StatusText>Aan: {notReachable ? '?' : props.light.state.isOn.toString()}</StatusText>
            <StatusText>Bereikbaar: {props.light.state.isReachable.toString()}</StatusText>
            <StatusText>Verbruik: {notReachable ? '?' : props.light.state.powerConsumption.toFixed() + ' watt'}</StatusText>
        </View>
    )
}

const Alert = (props: ViewProps) => {
    return (
        <View style={[{position: 'absolute'}, props.style]}>
            <AlarmIcon width={18} height={16} fill={'#D70040'}/>
        </View>
    );
}

export const EmptyLightView = (props: any) => {
    return <View {...props} style={[styles.container, { opacity: 0 }]} />
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        borderRadius: 10,
        margin: 7,
        padding: 15,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4
    }
});

export default LightView