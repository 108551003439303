import { ISliderProps, Slider } from '@miblanchard/react-native-slider';
import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import Colors from '../../constants/Colors';

const CustomThumb = () => (
    <View style={styles.thumb}/>
);

const FieldSlider = (props: ISliderProps) => {
    return (
        <Slider {...props}
            animateTransitions
            renderThumbComponent={CustomThumb}
            containerStyle={styles.sliderContainer}
            trackStyle={styles.sliderTrack}
            maximumValue={100}
            minimumValue={0}
            step={50}
            thumbTintColor={'white'}
            minimumTrackTintColor={Colors.mainColor}
            maximumTrackTintColor={Colors.backgroundColor}
        />
    )
}

const styles = StyleSheet.create({
    sliderContainer: {
        flex: 1,
        borderRadius: 100,
        overflow: 'hidden',
        alignSelf: 'center'
    },
    sliderTrack: {
        height: 40
    },
    thumb: {
        alignItems: 'center',
        backgroundColor: Colors.semiWhite,
        height: 40,
        width: 40,
        justifyContent: 'center',
        borderRadius: 100,
        borderWidth: 2,
        borderColor: 'rgba(158, 150, 150, .5)'
    }
});

export default FieldSlider