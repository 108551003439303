import { StyleSheet, Text, TextProps } from 'react-native'
import React from 'react'
import Fonts from '../../constants/Fonts';
import Colors from '../../constants/Colors';

const AltTitle = (props: TextProps) => {
    const { style, ...other } = props;
    return (
        <Text style={[
          styles.text,
          style,
      ]} {...other}/>
    )
}

const styles = StyleSheet.create({
    text: {
        fontFamily: Fonts.monterratsemibold,
        fontSize: 20,
        color: Colors.darkestGray,
    }
});

export default AltTitle