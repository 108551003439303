const FormatDataUtil = (data, numColumns) => {
    data = data.filter((item) => item.name.includes('Armatuur'));

    const numberOfFullRows = Math.floor(data.length / numColumns);

    let numberOfElementsLastRow = data.length - (numberOfFullRows * numColumns);
    while (numberOfElementsLastRow !== numColumns && numberOfElementsLastRow !== 0) {
      data.push({ id: numberOfElementsLastRow, empty: true });
      numberOfElementsLastRow++;
    }

    return data;
  };

export default FormatDataUtil