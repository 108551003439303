import { View } from 'react-native'
import React from 'react'
import ContentLoader from 'react-content-loader'
import { styles } from '../Club/FieldControllerView'
import Colors from '../../constants/Colors'

const FieldControllerSkeleton = () => {
    return (
        <View style={[styles.container, { height: 110 }]}>
            <ContentLoader backgroundColor={Colors.backgroundColor} foregroundColor={Colors.lightGray} viewBox="0 0 380 85">
                <circle cx="27" cy="22" r="13"/>
                <rect x="52" y="0" rx="22" ry="22" width="328" height="45" />
                <rect x="0" y="60" rx="7" ry="7" width="90" height="20"/>
            </ContentLoader>
        </View>
    )
}

export default FieldControllerSkeleton