import { View, Text, ActivityIndicator } from 'react-native'
import React from 'react'
import { setToken } from '../managers/AccountManager';
import { redirectToLogin } from '../managers/RedirectManager';

export const CallbackScreen = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('token');
    if(!tokenParam) {
        console.log('No token found in url');
        redirectToLogin();
        return (<Text>Er is iets fout gegaan...</Text>);
    }
    setToken(tokenParam);
    location.replace('choose');
    return (
        <View>
            <ActivityIndicator />
        </View>
    )
}