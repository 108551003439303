import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native'
import React from 'react'
import { useNavigation } from '@react-navigation/native'
import BackArrow from '../../assets/vectors/BackArrow.svg'

const BackButton = () => {
    const nav = useNavigation();
    return (
        <View style={styles.container}>
            <TouchableWithoutFeedback onPress={nav.goBack}>
                <View style={styles.button}>
                    <BackArrow />
                </View>
            </TouchableWithoutFeedback>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: 30,
        left: 20
    },
    button: {
        width: 40,
        height: 40,
        backgroundColor: 'white',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4
    }
});


export default BackButton