import { ClubModel } from '../../models/network/ClubModel';
import { FieldResponse } from '../../models/network/response/FieldResponse';
import { LightResponse } from '../../models/network/response/LightResponse';
import { cacheConfig, NewExpertsAPI } from '../NewExpertsAPI';

let baseUrl = 'https://api.newexperts-iot.nl/';
let edgeEndPoint = baseUrl + 'api/edge-devices';
let fieldEndPoint = edgeEndPoint + '/{0}/groups';
let lightEndPoint = edgeEndPoint + '/{0}/lightfixtures';

export function getEdgeDevices() {
   return NewExpertsAPI.get<ClubModel[]>(edgeEndPoint, cacheConfig);
}

export function getFields(azureDeviceId: string) {
   return NewExpertsAPI.get<FieldResponse>(fieldEndPoint.replace('{0}', azureDeviceId));
}

export function getLightFixtures(azureDeviceId: string) {
   return NewExpertsAPI.get<LightResponse>(lightEndPoint.replace('{0}', azureDeviceId));
}