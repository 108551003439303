import { Text, View, StyleSheet } from 'react-native'
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

export const NoAccessScreen = () => {

    return (
        <View style={styles.container}>
            <View style={styles.popupContainer}>
                <Text style={styles.text}>Je email staat niet in het systeem geregistreerd</Text>
            </View>
        </View>
    );

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    popupContainer: {
        padding: 20,
        borderRadius: 10,
        borderWidth: 5,
        borderColor: Colors.darkGray
    },
    text: {
        fontFamily: Fonts.monterrat,
        fontSize: 20
    }
});