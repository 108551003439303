import React, { Component } from 'react'
import { View, ViewProps } from 'react-native'

export default class SafeWidthView extends Component<ViewProps> {
  render () {
    return (
      <View
        {...this.props}
        style={[
          { width: '85%', alignSelf: 'center' },
          { ...(this.props.style as {}) }
        ]}
      />
    )
  }
}