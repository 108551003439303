import { ScrollView, StyleSheet, View } from 'react-native'
import React, { useState } from 'react'
import { NESignalR } from '../network/NESignalR';
import { GroupModel } from '../models/network/GroupModel';
import { ClubModel } from '../models/network/ClubModel';
import ClubHeaderView from '../components/Club/ClubHeaderView';
import SafeWidthView from '../components/SafeWidthView';
import AltTitle from '../components/General/AltTitle';
import FieldControllerView from '../components/Club/FieldControllerView';
import { getFields } from '../network/lib/EdgeDeviceManager';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import FieldControllerSkeleton from '../components/Skeleton/FieldControllerSkeleton';
import MasterButton from '../components/Club/MasterSwitch';
import BackButton from '../components/Club/BackButton';

export const HomeScreen = ({ route }) => {
    const navigation = useNavigation();
    const [fields, setFields] = useState<GroupModel[]>([]);
    const [signalR, setSignalR] = useState<NESignalR>();
    const clubModel = route.params;

    useFocusEffect(
        React.useCallback(() => {

            if(!clubModel) {
                navigation.navigate('Choose');
                return;
            }

            getFields(clubModel.id).then(response => setFields(response.data.model)).then(() => {
                let sigR = new NESignalR(clubModel.id);
                sigR.connect().then(() => setSignalR(sigR));
            }).catch(() => {
                alert('Kan geen verbinding maken met de lokale server');
                navigation.goBack();
            });

            return () => signalR?.disconnect(); // Disconnect on unmount
        }, [])
    );

    return (
        <ScrollView>
            <View style={{ flex: 1 }}>
                <ClubHeaderView clubModel={clubModel!} />

                <BackButton/>

                <SafeWidthView>
                    <MasterButton style={styles.masterButton} fields={fields} signalR={signalR}/>
                    <View>
                        <AltTitle>Zones</AltTitle>
                    </View>

                    {fields.length == 0 ? <SkeletonViews /> : fields.map(group => {
                        return (
                            <FieldControllerView field={group} key={group.id} signalR={signalR} />
                        );
                    })}

                </SafeWidthView>
            </View>
        </ScrollView>
    )
}


const SkeletonViews = () => {
    return (
        <>
            {[...Array(3)].map((x, i) =>
                <FieldControllerSkeleton key={i} />
            )}
        </>
    );
}

const styles = StyleSheet.create({
    masterButton: {
        alignSelf: 'center'
    }
});

export default HomeScreen