import { View, Text, ViewProps, StyleSheet } from 'react-native'
import React, { useState } from 'react'
import FieldSlider from './FieldSlider'
import { GroupModel } from '../../models/network/GroupModel';
import { NESignalR } from '../../network/NESignalR';
import Colors from '../../constants/Colors';
import SunIconSvg from './SunIconSvg';
import Fonts from '../../constants/Fonts';
import { Events } from '../../constants/Events';

interface FieldControllerViewProps extends ViewProps {
  field: GroupModel;
  signalR?: NESignalR;
}


const FieldControllerView = (props: FieldControllerViewProps) => {
  const [sliderValue, setSliderValue] = useState(props.field.slider);

  props.signalR?.onGroupStateChanged((group) => group.id === props.field.id && setSliderValue(group.slider));
  // @ts-ignore
  document.addEventListener(Events.groupChanged, (e) => setSliderValue(e.detail.value));

  return (
    <View style={styles.container}>
      <View style={styles.sliderContainer}>
        <View style={styles.iconContainer}>
          <SunIconSvg value={sliderValue} />
        </View>
        <FieldSlider
          value={[sliderValue]}
          onSlidingComplete={value => handleSliderComplete(props.field.id, value[0], props.signalR)}
          onValueChange={value => setSliderValue(value[0])}
        />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.fieldText}>{props.field.name}</Text>
        <Text style={styles.valueText}>{sliderValue}%</Text>
      </View>
    </View>
  )
}

const handleSliderComplete = (fieldId: string, newValue: number, signalR?: NESignalR) => {
  signalR?.setGroupState(fieldId, newValue > 0, newValue);
}

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.semiWhite,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 20,
    paddingHorizontal: 20,
    paddingTop: 20
  },
  sliderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  textContainer: {
    marginTop: 5,
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40
  },
  valueText: {
    fontFamily: Fonts.monterratsemibold,
    fontSize: 35,
    color: Colors.darkGray
  },
  fieldText: {
    position: 'absolute',
    left: 0,
    fontFamily: Fonts.monterrat,
    fontSize: 17,
    color: Colors.darkGray
  }
});

export default FieldControllerView