import * as signalR from '@microsoft/signalr'
import { HubConnection } from '@microsoft/signalr';
import { getToken } from '../managers/AccountManager';
import { GroupModel } from '../models/network/GroupModel';

export class NESignalR {

    private connection: HubConnection;
    private azureDeviceId: string;

    constructor(azureId: string) {
        this.azureDeviceId = azureId;
        this.connection = new signalR.HubConnectionBuilder()
        .withUrl('https://api.newexperts-iot.nl/hubs/eventhub', {
            accessTokenFactory: () => getToken()
        })
        .withAutomaticReconnect()
        .build();
    }

    async connect() {
        await this.connection.start();
        return await this.connection.invoke('JoinGroup', this.azureDeviceId);
    }

    async disconnect() {
        return await this.connection.stop();
    }

    async setGroupState(id: string, isOn: boolean, slider: number) {
        return await this.connection.invoke('SetGroupState', this.azureDeviceId, { isOn: isOn, id: id, slider: slider });
    }

    onGroupStateChanged(callback: (group: GroupModel) => void) {
        this.connection.on('UpdateGroupState', (group: GroupModel) => {
            callback(group);
        });
    }
}
