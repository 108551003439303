import { View, Text, ImageBackground, Image, StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'
import Colors from '../constants/Colors';
import PolynedHeaderView from '../components/PolynedHeaderView';
import Fonts from '../constants/Fonts';

const signinUri = 'https://api.newexperts-iot.nl/api/authenticate/sign-in/';
const signinGoogle = signinUri + 'google';
const signinMicrosoft = signinUri + 'microsoft';

export const LoginScreen = () => {
  return (
    <View style={{ flex: 1 }}>
      <ImageBackground source={require('../assets/images/background.jpg')} resizeMode='stretch' style={{ flex: 1 }}>
        <View style={styles.centerContainer}>
          <PolynedHeaderView/>
          <Text style={styles.welcomeText}>Welkom</Text>
          <View style={styles.vertical}>
            <View style={styles.signinContainer}>
              <Text style={styles.loginText}>Login</Text>
              <TouchableOpacity onPress={() => location.href = signinGoogle}>
                <Image style={styles.signinButton} source={require('../assets/images/btn_google_signin.png')} resizeMode='contain' />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => location.href = signinMicrosoft}>
                <Image style={styles.signinButton} source={require('../assets/images/btn_microsoft_signin.png')} resizeMode='contain' />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <Image style={styles.dmluxbottom} source={require('../assets/images/dmluxbottom.png')} resizeMode='contain' />
      </ImageBackground>
    </View>
  )
}

const styles = StyleSheet.create({
  dmluxbottom: {
    position: 'absolute',
    bottom: -20,
    width: 100,
    aspectRatio: 1
  },
  signinContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    justifyContent: 'center',
    padding: 10,
    borderRadius: 10,
  },
  signinButton: {
    width: 200,
    height: 50,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginHorizontal: 20,
    marginVertical: 10
  },
  centerContainer: {
    alignItems: 'center',
    flex: 1
  },
  welcomeText: {
    fontSize: 38,
    fontFamily: Fonts.monterrat,
    color: 'white',
    marginTop: 60
  },
  vertical: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  loginText: {
    fontFamily: Fonts.monterratsemibold,
    color: Colors.darkGray,
    fontSize: 25,
    margin: 10
  }
});