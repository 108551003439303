import StorageKeys from "../constants/StorageKeys";
import { deleteCache } from "./CacheManager";
import { redirectToLogin } from "./RedirectManager";

function getToken(): string {
    let localToken = localStorage.getItem(StorageKeys.USER_TOKEN);
    return localToken === null ? "" : localToken;
}

function deleteToken() {
    localStorage.removeItem(StorageKeys.USER_TOKEN);
}

function hasToken(): boolean {
    return localStorage.getItem(StorageKeys.USER_TOKEN) !== null;
}

function setToken(token: string) {
    localStorage.setItem(StorageKeys.USER_TOKEN, token);
}

function logout() {
    deleteToken();
    deleteCache();
    redirectToLogin();
}

export { getToken, deleteToken, hasToken, setToken, logout }