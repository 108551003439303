import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'
import ArrowIcon from '../../assets/vectors/ArrowIcon.svg'
import { logout } from '../../managers/AccountManager'
import Fonts from '../../constants/Fonts'

const LogoutButton = () => {
    return (
        <TouchableOpacity onPress={() => logout()}>
            <View style={styles.container}>
                <Text style={styles.text}>Uitloggen</Text>
                <ArrowIcon />
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        borderRadius: 10,
        flexDirection: 'row',
        backgroundColor: 'white',
        padding: 10
    },
    text: {
        fontFamily: Fonts.monterrat,
        paddingRight: 10
    }
})

export default LogoutButton