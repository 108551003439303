import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import AxiosStorage from 'axios-storage';
import { getToken } from '../managers/AccountManager'
import { redirectToLogin } from '../managers/RedirectManager';

let apiClient: AxiosInstance;

const cacheConfig: AxiosRequestConfig = {
  // @ts-ignore
  cacheConfig: true
}

function init() {
  AxiosStorage.config({
    storagePrefix: '', // no prefix
    storageMode: 'localStorage',
    maxAge: 7 * 24 * 60 * 60 * 1000 // 7 days
  });

  apiClient = axios.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    timeout: 5000,
    adapter: AxiosStorage.adapter
  });

  // reset token on no access error
  apiClient.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response && 401 === error.response.status) {
      redirectToLogin();
    } else {
      return Promise.reject(error);
    }
  });
}

export { init, cacheConfig, apiClient as NewExpertsAPI };