import { StyleSheet, Text, TextProps } from 'react-native'
import React from 'react'
import Fonts from '../../constants/Fonts';

const Title = (props: TextProps) => {
    const { style, ...other } = props;

  return (
      <Text style={[
        styles.text,
        style,
    ]} {...other}/>
  )
}

const styles = StyleSheet.create({
    text: {
        fontFamily: Fonts.monterrat,
        fontSize: 20,
        color: 'white',
        textShadowColor: 'rgba(0, 0, 0, 0.4)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 2
    }
});

export default Title