import { StyleSheet, Text, TextProps } from 'react-native';
import Fonts from '../../constants/Fonts';

const StatusText = (props: TextProps) => {
    return (
        <Text numberOfLines={1} style={[styles.text, props.style]}>{props.children}</Text>
    );
}

const styles = StyleSheet.create({
    text: {
        fontFamily: Fonts.monterrat,
        fontSize: 14,
        marginVertical: 2
    }
});

export default StatusText