import { View, Image, StyleSheet, Dimensions } from 'react-native'
import React from 'react'
import { ClubModel } from '../../models/network/ClubModel';
import Title from '../General/Title';
import { GetClubImage } from '../../managers/ImageManager';

interface ClubHeaderViewProps {
    clubModel: ClubModel
}

const ClubHeaderView = (props: ClubHeaderViewProps) => {
    return (
        <View style={styles.container}>
            <Image style={styles.image} source={GetClubImage(props.clubModel.imageUri)} resizeMode='stretch' />
            <View style={styles.titleContainer}>
                <Title>Verlichting</Title>
                <Title style={styles.mainTitle}>Blaashal</Title>
                <Title style={styles.subtitle}>{props.clubModel.locationName}</Title>
            </View>
        </View>
    )
}

const win = Dimensions.get('window');

const styles = StyleSheet.create({
    container: {
        top: 0,
        width: win.width,
        height: win.height / 4,
    },
    titleContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        width: '100%',
        height: '100%',
        opacity: 0.5,
        position: 'absolute'
    },
    mainTitle: {
        fontSize: 40,
    },
    subtitle: {
        fontSize: 20,
    }
});

export default ClubHeaderView;