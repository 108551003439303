import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Home: {
            screens: {
              HomeScreen: 'home',
            },
          },
          Lights: {
            screens: {
              LightsScreen: 'light',
            }
          },
          Chart: {
            screens: {
              ChartScreen: 'chart',
            },
          },
          Setting: {
            screens: {
              SettingScreen: 'setting',
            },
          },
        },
      },
      Choose: 'choose',
      Login: 'login',
      Callback: 'callback',
      NoAccess: 'noaccess',
      NotFound: '*',
    },
  },
};

export default linking;
