import React from 'react';
import { StyleSheet, View } from 'react-native';
import LogoutButton from '../components/Setting/LogoutButton';

export function SettingScreen() {
  return (
    <View style={styles.container}>
      <LogoutButton/>
      <View style={styles.separator} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
