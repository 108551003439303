import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import ArrowIcon from '../../assets/vectors/ArrowIcon.svg';
import Fonts from '../../constants/Fonts';
import Colors from '../../constants/Colors';
import { ClubModel } from '../../models/network/ClubModel';
import NavigationProp from '../../constants/NavigationProp';
import { GetClubImage } from '../../managers/ImageManager';


interface ClubViewProps {
    club: ClubModel
}

export class ClubView<T extends ClubViewProps & NavigationProp> extends Component<T> {

    render() {
        return (
            <TouchableOpacity style={styles.container} onPress={() => this.props.navigation.navigate('Root', { screen: 'home', params: this.props.club })}>
                <Image style={styles.image} source={GetClubImage(this.props.club.imageUri)} resizeMode='stretch' />
                <View style={styles.infoContainer}>
                    <Text style={styles.clubNameText}>{this.props.club.name}</Text>
                    <Text style={styles.locationText}>{this.props.club.locationName}</Text>
                </View>
                <ArrowIcon width={10} style={{ position: 'absolute', right: 20 }} />
            </TouchableOpacity>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: 100,
        backgroundColor: Colors.semiWhite,
        margin: 10,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 15
    },
    infoContainer: {
        flex: 1,
        height: '70%'
    },
    image: {
        height: '75%',
        aspectRatio: 1,
        margin: 15,
        marginRight: 20,
        borderRadius: 500
    },
    clubNameText: {
        fontFamily: Fonts.monterratsemibold,
        color: Colors.darkGray,
        fontSize: 15
    },
    locationText: {
        color: Colors.gray,
        fontFamily: Fonts.monterrat,
        marginTop: 5
    }

});

export default ClubView