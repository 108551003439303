import { View, Text, ViewProps, StyleSheet } from 'react-native'
import React, { useState } from 'react'
import Colors from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import SunFullIcon from '../../assets/vectors/SunFullIcon.svg'
import { Switch } from 'react-native-web-switch';
import { GroupModel } from '../../models/network/GroupModel';
import { NESignalR } from '../../network/NESignalR';
import { Events } from '../../constants/Events';

interface MasterButtonProps extends ViewProps {
    fields: GroupModel[]
    signalR?: NESignalR
}


const MasterButton = (props: MasterButtonProps) => {
    const [disabled, setDisabled] = useState(false);
    const isLoading = props.fields.length === 0;
    const masterOn = props.fields.filter(e => e.isOn && e.slider > 0).length > 0;
    return (
        <View style={[props.style, styles.container]}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <SunFullIcon width={29} height={29} style={{marginRight: 10}}/>
                <Text style={styles.text}>Alle zones</Text>
            </View>
            {!isLoading && <Switch
                style={styles.switch}
                value={masterOn}
                trackColor={{ true: Colors.mainColor, false: Colors.lightGray }}
                disabled={disabled || isLoading}
                onChange={(e) => setAllFields(e.value, props.fields, setDisabled, props.signalR)}
            />}
        </View>
    )
}

function setAllFields(on: boolean, fields: GroupModel[], setDisabled: (value: boolean) => void, signalR?: NESignalR) {
    const newValue =  on ? 100 : 0;
    fields.forEach(field => signalR?.setGroupState(field.id, on, newValue));
    document.dispatchEvent(new CustomEvent(Events.groupChanged, { detail: { value: newValue } }));
    setDisabled(true);
    setTimeout(() => setDisabled(false), 1000);
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.semiWhite,
        borderRadius: 10,
        marginTop: -20,
        marginBottom: 25,
        paddingHorizontal: 20,
        paddingVertical: 20,
        width: '100%'
    },
    text: {
        fontFamily: Fonts.monterratsemibold,
        color: Colors.darkGray,
        fontSize: 18
    },
    switch: {
        position: 'absolute',
        right: 0,
        marginTop: -28
    }
});

export default MasterButton