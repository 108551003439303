import { View, Image, StyleSheet } from 'react-native'
import React from 'react'

const PolynedHeaderView = () => {
    return (
        <View style={styles.container}>
            <Image style={styles.radial} source={require('../assets/images/radial.png')} resizeMode='contain' />
            <Image style={styles.polyned} source={require('../assets/images/polyned.png')} resizeMode='contain' />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        width: '100%'
    },
    polyned: {
        width: 120,
        aspectRatio: 1
    },
    radial: {
        width: '100%',
        height: 100,
        position: 'absolute',
    },
});

export default PolynedHeaderView