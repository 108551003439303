import { View, Text, FlatList, ScrollView } from 'react-native'
import React, { useEffect, useState } from 'react'
import { getEdgeDevices, getLightFixtures } from '../network/lib/EdgeDeviceManager'
import { ClubModel } from '../models/network/ClubModel'
import { LightModel } from '../models/network/LightModel';
import LightView, { EmptyLightView } from '../components/Light/LightView';
import SafeWidthView from '../components/SafeWidthView';
import FormatData from '../utils/FormatDataUtil';
import { useFocusEffect } from '@react-navigation/native';

interface IClubLights {
  club: ClubModel;
  lightFixtures: LightModel[];
}

const numColumns = 2;

export const LightScreen = () => {
  const [clubs, setClubs] = useState<IClubLights[]>([])

  useFocusEffect(
    React.useCallback(() => {
      const clubLights: IClubLights[] = [];

      // request edge devices
      getEdgeDevices().then(response => {
        // for each edge device
        response.data.forEach(club => {
          // request light fixtures
          getLightFixtures(club.id).then(response => {
            const newClub = { club: club, lightFixtures: response.data.model };
            setClubs(clubs => [newClub, ...clubs]);
          })
        });
      });
      setClubs(clubLights)
    }, [])
  );

  const renderItem = ({ item, index }) => {
    if (item.empty === true) return <EmptyLightView key={index} />
    return <LightView light={item} key={item.id} />
  };

  return (
    <ScrollView>
      <SafeWidthView>
        {clubs && clubs.map(club => {
          return (
            <View key={club.club.id}>
              <Text>{club.club.name}</Text>
              <FlatList data={FormatData(club.lightFixtures, numColumns)} renderItem={renderItem} numColumns={numColumns} />
            </View>
          )
        })}
      </SafeWidthView>
    </ScrollView>
  )
}
