import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, useTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import ChartIcon from '../assets/vectors/tab/ChartIcon.svg';
import HomeIcon from '../assets/vectors/tab/HomeIcon.svg';
import SettingIcon from '../assets/vectors/tab/SettingIcon.svg';
import LightIcon from '../assets/vectors/tab/LightIcon.svg';
import Colors from '../constants/Colors';
import { hasToken } from '../managers/AccountManager';
import HomeScreen from '../screens/HomeScreen';
import LinkingConfiguration from './LinkingConfiguration';
import { redirectToLogin } from '../managers/RedirectManager';
import { CallbackScreen, ChartScreen, ChooseClubScreen, LightScreen, LoginScreen, NoAccessScreen, NotFoundScreen, SettingScreen } from '../screens';

export default function Navigation() {
  const theme = useTheme();
  theme.colors.background = Colors.backgroundColor;
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={theme}
      fallback={LoginScreen}>
      <RootNavigator />
    </NavigationContainer>
  );
}
const defaultScreenOptions = {
  headerShown: false
}

const Stack = createNativeStackNavigator();

function RootNavigator() {
  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen name='Login'component={LoginScreen}/>
      <Stack.Screen name='NoAccess'component={NoAccessScreen}/>
      <Stack.Screen name='Callback' component={CallbackScreen}/>
      <Stack.Screen name='Root' component={BottomTabNavigator}/>
      <Stack.Screen name='Choose' component={ChooseClubScreen}/>
      <Stack.Screen name='NotFound' component={NotFoundScreen} options={{ title: 'Oops!' }} />
    </Stack.Navigator>
  );
}

const BottomTab = createBottomTabNavigator();

function BottomTabNavigator() {
  if(!hasToken()) {
    redirectToLogin();
  }
  return (
    <BottomTab.Navigator
      initialRouteName='home'
      screenOptions={{
        tabBarActiveTintColor: Colors.mainColor,
        headerShown: false,
        tabBarShowLabel: false
      }}>
      <BottomTab.Screen
        name='home'
        component={HomeScreen}
        options={{
          tabBarIcon: ({ color, size }) =>
            <HomeIcon width={size} height={size} stroke={color} />
        }}
      />
      <BottomTab.Screen
        name='lights'
        component={LightScreen}
        options={{
          tabBarIcon: ({ color, size }) =>
            <LightIcon width={size} height={size} fill={color} />
        }}
      />
      <BottomTab.Screen
        name='chart'
        component={ChartScreen}
        options={{
          tabBarIcon: ({ color, size }) =>
            <ChartIcon width={size} height={size} stroke={color} />
        }}
      />
      <BottomTab.Screen
        name='setting'
        component={SettingScreen}
        options={{
          tabBarIcon: ({ color, size }) =>
            <SettingIcon width={size} height={size} fill={color} />
        }}
      />
    </BottomTab.Navigator>
  );
}